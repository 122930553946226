<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <h1>RECENT BIDS</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'Evaluation' })"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <datatable
      :headers="headers"
      :staticData="items"
      :componentURL="componentURL"
      :search="true"
    ></datatable>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";

export default {
  components: { Datatable },

  data() {
    return {
      componentURL: "Bid",
      headers: [
        { text: "Rep", value: "rep" },
        { text: "Division", value: "division" },
        { text: "Date", value: "bid_date" },
        { text: "Customer", value: "project" },
        { text: "Bid #", value: "bid_num" },
        { text: "Initial?", value: "initial_bid" },
        { text: "Amount", value: "amount" },
        { text: "Copy?", value: "copy" },
      ],
      items: [],
      "table-data": [[{ Header: "PAST DUE\t\t\t" }]],
    };
  },
};
</script>

<style></style>
